import React from "react"
import { graphql } from "gatsby"
import { InnerPageNavigation } from "../components/Navigation"
import { Layout } from "../components/Layout"
import { PageTileGroup, PageTile } from "../components/PageTile"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

interface InnerpageTemplateProps {
  title: string
  description: string
  namespace: string
  sideart: ImageDataLike
  body: string
}

export const InnerpageTemplate: React.FC<InnerpageTemplateProps> = ({
  title,
  description,
  body,
  namespace,
  sideart,
}) => {
  const sideArtImage = getImage(sideart)
  return (
    <Layout title={title} description={description}>
      <InnerPageNavigation />
      <div
        className={`container flex-grow px-4 mx-auto mt-10 mb-10 innerpage relative ${namespace}`}
      >
        {sideArtImage ? (
          <div className="absolute top-0 right-0 hidden mr-0 lg:mr-2 xl:mr-16 lg:block sideart">
            <GatsbyImage alt="" image={sideArtImage} />
          </div>
        ) : null}
        <div className="mx-auto md:max-w-xl xl:max-w-2xl">
          <h1 className="text:3xl sm:text-4xl md:text-4.5xl font-serif mb-3 text-dark-burgundy">
            {title}
          </h1>
          <MDXRenderer className={namespace}>{body}</MDXRenderer>
        </div>
      </div>
      <PageTileGroup />
    </Layout>
  )
}

interface InnerPageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string
        description: string
        namespace: string
        sideart: ImageDataLike
      }
      body: string
    }
  }
}

const InnerPage = ({ data }: InnerPageProps) => {
  const { frontmatter, body } = data.mdx
  return (
    <InnerpageTemplate
      title={frontmatter.title}
      description={frontmatter.description}
      body={body}
      namespace={frontmatter.namespace}
      sideart={frontmatter.sideart}
    />
  )
}

export default InnerPage

export const InnerPageQuery = graphql`
  query InnerPageByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        namespace
        sideart {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AVIF, WEBP, AUTO]
            )
          }
        }
      }
    }
  }
`
