import React from "react"
import { Location } from "@gatsbyjs/reach-router"
import { Link } from "gatsby"

interface PageTileProps {
  title: string
  image: string
  to: string
  description?: string
}

export const PageTile: React.FC<PageTileProps> = ({
  title,
  image,
  to,
  description,
}) => {
  const bgColor = "rgba(255, 255, 255, 0.56)"
  return (
    <Link
      to={to}
      className={`${
        description ? "group" : null
      } no-underline hover:no-underline w-full mr-0 xs:mr-4 md:mr-8 mb-8 xxs:mb-0 last:mr-0`}
    >
      <div
        className="flex items-center justify-center overflow-hidden bg-bottom bg-no-repeat bg-cover page-tile"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div
          className="absolute flex w-full h-20 text-center group-hover:hidden"
          style={{ backgroundColor: bgColor }}
        >
          <span className="self-center w-full px-2 text-xl font-medium leading-tight tracking-widest text-center uppercase xs:text-xxs sm:text-xs md:text-base lg:text-lg xl:text-lg sm:px-4 md:px-6 lg:px-8 text-cod-gray">
            {title}
          </span>
        </div>
        {description ? (
          <div
            className="absolute z-10 items-center hidden w-full h-full p-2 text-sm font-light text-black group-hover:flex group-hover:border group-hover:border-gray-200 lg:text-base lg:p-3"
            style={{ backgroundColor: bgColor }}
          >
            {description}
          </div>
        ) : null}
      </div>
    </Link>
  )
}

export const PageTileGroup = () => {
  return (
    <div className="-z-10 nav-background">
      <Location>
        {({ location }) => {
          switch (location.pathname) {
            case "/history/":
              return (
                <div className="flex flex-col items-center w-full p-8 mx-auto sm:max-w-3xl xs:flex-row">
                  <PageTile
                    title="Conceptual Framework"
                    to="/conceptual-framework/"
                    image={"/media/images/conceptual-framework.jpg"}
                  />
                  <PageTile
                    title="Lines of Action"
                    image={"/media/images/lines-of-action.jpg"}
                    to="/lines-of-action/"
                  />
                  <PageTile
                    title="Resources"
                    image={"/media/images/resources.jpg"}
                    to="/resources/"
                  />
                </div>
              )
            case "/conceptual-framework/":
              return (
                <div className="flex flex-col items-center w-full p-8 mx-auto sm:max-w-3xl xs:flex-row">
                  <PageTile
                    title="History"
                    to="/history/"
                    image={"/media/images/history.jpg"}
                  />
                  <PageTile
                    title="Lines of Action"
                    image={"/media/images/lines-of-action.jpg"}
                    to="/lines-of-action/"
                  />
                  <PageTile
                    title="Resources"
                    image={"/media/images/resources.jpg"}
                    to="/resources/"
                  />
                </div>
              )
            case "/lines-of-action/":
              return (
                <div className="flex flex-col items-center w-full p-8 mx-auto sm:max-w-3xl xs:flex-row">
                  <PageTile
                    title="History"
                    to="/history/"
                    image={"/media/images/history.jpg"}
                  />
                  <PageTile
                    title="Conceptual Framework"
                    to="/conceptual-framework/"
                    image={"/media/images/conceptual-framework.jpg"}
                  />
                  <PageTile
                    title="Resources"
                    image={"/media/images/resources.jpg"}
                    to="/resources/"
                  />
                </div>
              )
            case "/resources/":
              return (
                <div className="flex flex-col items-center w-full p-8 mx-auto sm:max-w-3xl xs:flex-row">
                  <PageTile
                    title="History"
                    to="/history/"
                    image={"/media/images/history.jpg"}
                  />
                  <PageTile
                    title="Conceptual Framework"
                    to="/conceptual-framework/"
                    image={"/media/images/conceptual-framework.jpg"}
                  />
                  <PageTile
                    title="Lines of Action"
                    image={"/media/images/lines-of-action.jpg"}
                    to="/lines-of-action/"
                  />
                </div>
              )
            default:
              return (
                <div className="flex flex-col items-center w-full p-8 mx-auto sm:max-w-4xl xs:flex-row">
                  <PageTile
                    title="History"
                    to="/history/"
                    image={"/media/images/history.jpg"}
                  />
                  <PageTile
                    title="Conceptual Framework"
                    to="/conceptual-framework/"
                    image={"/media/images/conceptual-framework.jpg"}
                  />
                  <PageTile
                    title="Lines of Action"
                    image={"/media/images/lines-of-action.jpg"}
                    to="/lines-of-action/"
                  />
                  <PageTile
                    title="Resources"
                    image={"/media/images/resources.jpg"}
                    to="/resources/"
                  />
                </div>
              )
          }
        }}
      </Location>
    </div>
  )
}
